// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Simple Circular Loader */
.circular-loader {
  border: 4px solid #f3f3f3; /* Light gray color */
  border-top: 4px solid #09bf70; /* Blue color for the top */
  border-radius: 50%; /* Make it a circle */
  width: 40px; /* Set the size of the loader */
  height: 40px; /* Set the size of the loader */
  animation: spin 1s linear infinite; /* Animation: rotate infinitely */
}

/* Keyframes for rotating animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/reUsableCmponent/Tables/paymentTable.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,yBAAyB,EAAE,qBAAqB;EAChD,6BAA6B,EAAE,2BAA2B;EAC1D,kBAAkB,EAAE,qBAAqB;EACzC,WAAW,EAAE,+BAA+B;EAC5C,YAAY,EAAE,+BAA+B;EAC7C,kCAAkC,EAAE,iCAAiC;AACvE;;AAEA,qCAAqC;AACrC;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["/* Simple Circular Loader */\n.circular-loader {\n  border: 4px solid #f3f3f3; /* Light gray color */\n  border-top: 4px solid #09bf70; /* Blue color for the top */\n  border-radius: 50%; /* Make it a circle */\n  width: 40px; /* Set the size of the loader */\n  height: 40px; /* Set the size of the loader */\n  animation: spin 1s linear infinite; /* Animation: rotate infinitely */\n}\n\n/* Keyframes for rotating animation */\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
